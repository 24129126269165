import React, { Fragment, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/authHook';

import './App.css';
import LoadingDotIndicator from './shared/components/UIElements/LoadingIndicator/LoadingDotIndicator';

const MainNavigation = React.lazy(() =>
   import('./shared/components/Navigation/Header/MainNavigation')
);
const Footer = React.lazy(() =>
   import('./shared/components/Navigation/Footer/Footer')
);

const Home = React.lazy(() => import('./pages/HomePage/Home'));
const Services = React.lazy(() => import('./pages/Services/Services'));
const About = React.lazy(() => import('./pages/About/About'));
const PrivacyStatement = React.lazy(() =>
   import('./pages/PrivacyStatement/PrivacyStatement')
);

const Admin = React.lazy(() => import('./quotes/pages/Admin'));
const NewQuote = React.lazy(() => import('./quotes/pages/NewQuote'));
const QuoteScreen = React.lazy(() => import('./quotes/pages/QuoteScreen'));
const UpdateQuote = React.lazy(() => import('./quotes/pages/UpdateQuote'));
const PostComment = React.lazy(() =>
   import('./quotes/components/singleQuote/PostComment/PostComment')
);
const AuthorQuotes = React.lazy(() => import('./quotes/pages/AuthorQuotes'));

const Auth = React.lazy(() => import('./user/pages/Auth'));
const User = React.lazy(() => import('./user/pages/User'));
const EditProfile = React.lazy(() => import('./user/components/EditProfile'));
const ResetPassword = React.lazy(() => import('./user/pages/ResetPassword'));
const ForgotPassword = React.lazy(() => import('./user/pages/ForgotPassword'));

const Authors = React.lazy(() => import('./author/pages/Authors'));

const App = () => {
   const { token, login, logout, userId, role } = useAuth();

   // console.log('token: ', token, 'userd: ', userId, 'authorId: ', authorId);

   let routes;

   if (token) {
      routes = (
         <Fragment>
            <Route path='' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/about' element={<About />} />
            <Route path='/isLoggingIn' element={<Navigate to='/' />} />
            <Route
               path='/personvernerklaering'
               element={<PrivacyStatement />}
            />
            <Route path='/forum' element={<Authors />} />
            <Route path='/forum/quotes' element={<AuthorQuotes />} />
            <Route path='/forum/quotes/:quoteId' element={<QuoteScreen />} />
            <Route path='/user/account/myProfile' element={<User />} />
            <Route path='/user/account/editProfile' element={<EditProfile />} />
            <Route
               path='/forum/quotes/:quoteId/createComment'
               element={<PostComment />}
            />
            {/* author routes */}
            <Route path='/admin' element={<Admin />} />
            <Route path='/admin/addquote' element={<NewQuote />} />
            <Route
               path='/forum/admin/editQuote/:quoteId'
               element={<UpdateQuote />}
            />
         </Fragment>
      );
   } else {
      routes = (
         <Fragment>
            <Route path='' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/about' element={<About />} />
            <Route path='/isLoggingIn' element={<Auth />} />
            <Route
               path='/personvernerklaering'
               element={<PrivacyStatement />}
            />
            <Route path='/account/resetPassword' element={<ForgotPassword />} />
            <Route path='/updatePassword/:token' element={<ResetPassword />} />
         </Fragment>
      );
   }

   return (
      <Fragment>
         <AuthContext.Provider
            value={{
               isLoggedIn: !!token,
               token: token,
               userId: userId,
               role: role,
               login: login,
               logout: logout,
            }}
         >
            <MainNavigation />
            <main>
               <Suspense
                  fallback={
                     <div className='centered'>
                        <LoadingDotIndicator />
                     </div>
                  }
               >
                  <div className='min-h'>
                     <Routes>
                        {routes}
                        <Route element={<Footer />} />

                        {/* redirect if entered invalid url */}
                        <Route path='/*' element={<Navigate to='/' />} />
                        <Route element={<Footer />} />
                     </Routes>
                  </div>
               </Suspense>
            </main>
            <Footer />
         </AuthContext.Provider>
      </Fragment>
   );
};

export default App;
