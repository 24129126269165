import React from 'react';

import './Dot.css';

const LoadingDotIndicator = (props) => {
   return (
      <div className='shadow-raised loading'>
         <div className='loading-dot ldc-green'></div>
         <div className='loading-dot ldc-green'></div>
         <div className='loading-dot ldc-blue'></div>
         <div className='loading-dot ldc-blue'></div>
      </div>
   );
};

export default LoadingDotIndicator;
